<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Month on Month Revenue</b-card-title>
      
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="JSON.parse(JSON.stringify(chartData))"
        :options="JSON.parse(JSON.stringify(chartOptions))"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, 
} from 'bootstrap-vue'
//import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
//import chartjsData from './chartjsData'
import dashboardService from '@/services/dashboard-service'
//import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    //flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      
      //rangePicker: [],
      
    }
  },
  props: {
    
    chartData: {
      type: Object,
      required: false,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    
  },
  created() {
    
  }
}
</script>

<style lang="scss">
//@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
