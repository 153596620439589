<template>
    <div>
        <b-row class="match-height">
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >
                
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'Last Year')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">Last Year ({{ revenueData.filter(item => item.param === 'Last Year')[0].cnt }})</span>
                    <b-progress
                        value="50"
                        max="100"
                        height="6px"
                        variant="warning"
                    />
                    <!-- chart -->
                    <!-- <vue-apex-charts
                        height="70"
                        :options="statisticsOrder.chartOptions"
                        :series="statisticsOrder.series"
                    /> -->
                </b-card>
            </b-col>
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'YTD')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">YTD <strong>({{ revenueData.filter(item => item.param === 'YTD')[0].cnt }})</strong></span>
                    
                </b-card>
            </b-col>
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'Average Monthly')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">Avg Monthly</span>
                </b-card>
            </b-col>
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'Average per Candidate')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">Avg/Candidate </span>
                </b-card>
            </b-col>
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'Pending Joiners')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">Pending Joiners <strong>({{ revenueData.filter(item => item.param === 'Pending Joiners')[0].cnt }})</strong></span>
                </b-card>
            </b-col>
            <b-col
                lg="2"
                cols="6"
            >
                <b-card
                body-class="pb-50"
                >                
                    <h2 class="font-weight-bolder">
                        {{ revenueData.filter(item => item.param === 'Dropped')[0].revenue }} Lacs
                    </h2>
                    <span class="text-small" style="color:#605f5f; font-size: 12px">Lost/Dropped <strong>({{ revenueData.filter(item => item.param === 'Dropped')[0].cnt }})</strong></span>
                </b-card>
            </b-col>    
                
    </b-row>
    </div>
    
  </template>
  
  <script>
  import { BCard, BRow, BCol, BProgress } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  //import { $themeColors } from '@themeConfig'
  
  //const $barColor = '#f3f3f3'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BProgress
      //VueApexCharts,
    },
    data() {
      return {
        
      }
    },
    props: {
        revenueData: {
            type: Array,
            required: false,
        },
    },
  }
  </script>
  <<style scoped>
  .font-weight-bolder {
    font-size: 1.614rem !important;
    font-weight: 500 !important;
  }
  </style>
  