<template>
    <b-card
      no-body
      class="card-browser-states"
    >
      <b-card-header>
        <div>
          <b-card-title>Least Revenue Generating Clients</b-card-title>
          <!-- <b-card-text class="font-small-2">
            Counter August 2020
          </b-card-text> -->
        </div>
  
        <!-- <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item href="#">
            Last 28 Days
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Month
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Year
          </b-dropdown-item>
        </b-dropdown> -->
      </b-card-header>
  
      <!-- body -->
      <b-card-body>
        
        <div
          v-for="(client,index) in bottomClient"
          :key="index"
          class="browser-states"
        >
          <b-media no-body>
            <!-- <b-media-aside class="mr-1">
              <b-img
                :src="browser.browserImg"
                alt="browser img"
              />
            </b-media-aside> -->
            <b-media-body>
              <h6 class="align-self-center my-auto">
                {{ client.client }}
              </h6>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{ client.revenue }}</span>
          </div>
        </div>
      </b-card-body>
      <!--/ body -->
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
  } from 'bootstrap-vue'
  //import VueApexCharts from 'vue-apexcharts'
  //import { $themeColors } from '@themeConfig'
  /* eslint-disable global-require */
  const $trackBgColor = '#e9ecef'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      BImg,
      BDropdown,
      BDropdownItem,
      //VueApexCharts,
    },
    data() {
      return {
        chartData: [],
        
      }
    },
    props: {
        bottomClient: {
            type: Array,
            required: false,
        },
    },
    created() {
      // for (let i = 0; i < this.browserData.length; i += 1) {
      //   const chartClone = JSON.parse(JSON.stringify(this.chart))
      //   chartClone.options.colors[0] = this.chartColor[i]
      //   chartClone.series[0] = this.chartSeries[i]
      //   this.chartData.push(chartClone)
      // }
    },
  }
  </script>
  