<template>
    <div v-if="$can('read','revenue_dashboard')">
      <!-- Miscellaneous Charts -->
      <div class="demo-inline-spacing mb-1"><h2>Revenue</h2> 
        <v-select
          v-model="yearSelected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="yearOptions"
          :reduce="(option) => option.title"
          @input="getYearSelected"
        /> 
        <div class="d-flex align-items-center" style="width: 250px">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="flatpickrConfig"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
          />
        </div>
        <span>(All amounts mentioned are in lacs)</span>
      </div>
      <div v-if="error"> {{ error }}</div>
      <b-row class="match-height">
        <b-col
        cols="12"
        >
          <revenue-summary :revenue-data="revenueTopData"/>
        </b-col>  
      </b-row>
      <b-row>
        <b-col md="5">
          <revenue-monthly :chart-data="momRevenueBarChartData.data" :chart-options="momRevenueBarChartData.options"/>
        </b-col>
        <!-- <b-col md="6">
          <revenue-monthly-line />
        </b-col> -->
        <b-col md="7">
          <revenue-clientwise :chartData="clientRevenueBarChartData.data" :chartOptions="clientRevenueBarChartData.options" />
        </b-col>
        <b-col md="5">
          <revenue-team-leader-wise :chart-data="tlRevenueBarChartData.data" :chart-options="tlRevenueBarChartData.options"/>
        </b-col>
        <b-col md="7">
          <revenue-recruiter-wise :chart-data="recruiterRevenueBarChartData.data" :chart-options="recruiterRevenueBarChartData.options"/>
        </b-col>
        <b-col md="6">
          <revenue-bottom-client :bottom-client="leastClientRevenueData"/>
        </b-col>
        <b-col md="6">
          <revenue-bottom-recruiters :bottom-recruiter="leastRecruiterRevenueData"/>
        </b-col>
      </b-row>             
    </div>
</template>      
<script>
import { BRow, BCol } from 'bootstrap-vue'
import RevenueSummary from './RevenueSummary.vue'
import RevenueMonthly from './RevenueMonthly.vue'
import RevenueClientwise from './RevenueClientwise.vue'
import RevenueRecruiterWise from './RevenueRecruiterWise.vue'
import RevenueTeamLeaderWise from './RevenueTeamLeaderWise.vue'
import RevenueMonthlyLine from './RevenueMonthlyLine.vue'
import RevenueBottomClient from './RevenueBottomClient.vue'
import RevenueBottomRecruiters from './RevenueBottomRecruiters.vue'
import dashboardService from '@/services/dashboard-service'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
      BRow,
      BCol,
      RevenueSummary,
      RevenueMonthly,
      RevenueClientwise,
      RevenueMonthlyLine,
      RevenueTeamLeaderWise,
      RevenueRecruiterWise,
      RevenueBottomRecruiters,
      RevenueBottomClient,
      vSelect,
      flatPickr
    },
    data() {
      return {
        revenueData: [],
        yearOptions: [
          {title: "2019-2020"},
          {title: "2020-2021"},
          {title: "2021-2022"},
          {title: "2022-2023"},
        ],
        rangePicker: null,
        yearSelected: '',
        error: '',
        flatpickrConfig: {
          mode: 'range',
          onChange: this.handleDateChange,
        },
        currFinancialYear: '',
        // startDate: null,
        // endDate: null,
        revenueTopData: [],
        leastClientRevenueData: [],
        leastRecruiterRevenueData: [],
        employees: [],
        rejoinedEmployees: [],
        momRevenueBarChartData: {},
        clientRevenueBarChartData: {},
        tlRevenueBarChartData: {},
        recruiterRevenueBarChartData: {}
      }
    },
    watch: {
      rangePickr: function(newDateRange) {
        this.handleDateChange(this.rangePickr)
      }
    },
    
    created() {
      // Subscribers gained
      // Orders
      this.yearOptions = this.getFinancialYears(2019)

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      if (currentMonth >= 4) {
          this.currFinancialYear = currentYear + '-' + (currentYear + 1);
      } else {
          this.currFinancialYear = (currentYear - 1) + '-' + currentYear;
      }
      //this.yearOptions = this.yearOptions.push(this.currFinancialYear)
      console.log(this.currFinancialYear);
      this.yearSelected = this.currFinancialYear

      const startYear = this.yearSelected.split("-")[0]
      const endYear = this.yearSelected.split("-").slice(-1)[0]
      const startDate = startYear + '-04-01'
      const endDate = endYear + '-03-31'
      console.log('start and end date', startDate, endDate)
      const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
      const userEmpid = JSON.parse(localStorage.getItem('userData')).emp_id
      const userMgrid = JSON.parse(localStorage.getItem('userData')).mgr_id
      // this.accessCheck = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' ? false : true
      // console.log(this.accessCheck)
      // if(this.accessCheck === true) {
      if(this.$can('read','revenue_dashboard')) {
        this.getRevenueData(startDate,endDate)
      } else {
          this.$router.push({ name: "misc-not-authorized" });
      }

      
    },
    methods: {
      getRevenueData(startDate,endDate) {
        this.error = ''
        dashboardService.getRevenueDashboard(startDate, endDate).then(res => {
          console.log(res)
            if(res.status === "OK") {
              //this.revenueData = res
              //console.log(this.revenueData)
              // let copy = Object.assign({}, res.data);
              // let copy1 = Object.assign({}, res.barChartData);
              // console.log('revenue json',copy, copy1);

              this.revenueTopData = res.data.revenue
              console.log('revenue', this.revenueTopData)
              this.leastClientRevenueData = res.data.leastClientRevenue
              this.leastRecruiterRevenueData = res.data.leastRecruiterRevenue
              this.employees = res.data.employees
              this.rejoinedEmployees = res.data.rejoinedEmployees
              console.log(this.leastClientRevenueData, this.leastRecruiterRevenueData)
              this.momRevenueBarChartData = res.barChartData.momRevenueBarChartData
              this.clientRevenueBarChartData = res.barChartData.clientRevenueBarChartData
              this.tlRevenueBarChartData = res.barChartData.tlRevenueBarChartData
              this.recruiterRevenueBarChartData = res.barChartData.recruiterRevenueBarChartData
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
            }
            else if ((res.status === 500 ) ) {
              this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
            } else {
              this.error = res
            }
          })
      },
      getYearSelected(title) {
        console.log('year selected on change',this.yearSelected, title)
        const startYear = this.yearSelected.split("-")[0]
        const endYear = this.yearSelected.split("-").slice(-1)[0]
        const startDate = startYear + '-04-01'
        const endDate = endYear + '-03-31'
        console.log('Selected date:',startDate, 'end', endDate);
        this.getRevenueData(startDate,endDate)
      },
      handleDateChange() {
        // Perform any desired operations with the selected date
        //         const parts = str.split(" ");
        // const substring = parts.slice(-1)[0];
        console.log('Selected date:',this.rangePicker);
        const startDate = this.rangePicker.split(" ")[0];
        const endDate = this.rangePicker.split(" ").slice(-1)[0]
        console.log('Selected date:',startDate, 'end', endDate);
        this.getRevenueData(startDate,endDate)
      },
      getFinancialYears(startYear) {
        const currentYear = new Date().getFullYear();
        
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
          const financialYearStart = new Date(year, 3, 1); // Assuming financial year starts in April
          const financialYearEnd = new Date(year + 1, 2, 31); // Assuming financial year ends in March
          const financialYear = `${financialYearStart.getFullYear()}-${financialYearEnd.getFullYear()}`;
          years.push({title: financialYear});
        }

        return years;
      }
      
    }
    
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  
  </style>