import { $themeColors } from '@themeConfig'
import dashboardService from '@/services/dashboard-service'
// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  dangerColorShade: '#ea5455',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
let momChart = {}
// dashboardService.getRevenueDashboard('2022','2023').then(res => {
//     if(res.status === "OK") {
//         console.log(res)
//         //momChart = res.data
//         momChart = {
//           data: res.data.data,
//           options: res.data.options
//         }
//         console.log(momChart.data)
//         console.log(momChart.options)


//         // momChart.data = d.map(result => result.month);
//         // momChart.labels = d.map(result => result.revenue);
//         // console.log(momChart.data)
//         // console.log(momChart.labels)
//     }
// })

export default {

  latestBarChart: momChart,

  // latestBarChart: {
  //   data: {
  //     labels: momChart.labels,
  //     datasets: [
  //       {
  //         data: momChart.data,
  //         backgroundColor: [chartColors.successColorShade, chartColors.warningColorShade, chartColors.successColorShade, 
  //           chartColors.successColorShade, chartColors.successColorShade, chartColors.successColorShade, 
  //           chartColors.dangerColorShade, chartColors.warningColorShade, chartColors.successColorShade, 
  //           chartColors.successColorShade, chartColors.warningColorShade, chartColors.successColorShade],
  //         borderColor: 'transparent',
  //       },
  //     ],
  //   },
  //   options: { 
  //     elements: {
  //       rectangle: {
  //         borderWidth: 2,
  //         borderSkipped: 'bottom',
  //       },
  //     },
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     responsiveAnimationDuration: 500,
  //     legend: {
  //       display: false,
  //     },
  //     tooltips: {
  //       // Updated default tooltip UI
  //       shadowOffsetX: 1,
  //       shadowOffsetY: 1,
  //       shadowBlur: 8,
  //       shadowColor: chartColors.tooltipShadow,
  //       backgroundColor: $themeColors.light,
  //       titleFontColor: $themeColors.dark,
  //       bodyFontColor: $themeColors.dark,
  //     },
  //     scales: {
  //       xAxes: [
  //         {
  //           display: true,
  //           gridLines: {
  //             display: true,
  //             color: chartColors.grid_line_color,
  //             zeroLineColor: chartColors.grid_line_color,
  //           },
  //           scaleLabel: {
  //             display: false,
  //           },
  //           ticks: {
  //             fontColor: chartColors.labelColor,
  //           },
  //         },
  //       ],
  //       yAxes: [
  //         {
  //           display: true,
  //           gridLines: {
  //             color: chartColors.grid_line_color,
  //             zeroLineColor: chartColors.grid_line_color,
  //           },
  //           ticks: {
  //             stepSize: 1000000,
  //             min: 0,
  //             max: 5000000,
  //             fontColor: chartColors.labelColor,
  //           },
  //         },
  //       ],
  //     },
  //   },
  // },
  latestBarChartClientwise: {
    data: {
      labels: ['KPMG', 'Genpact', 'Golgman Sachs', 'Wipro', 'LTI', 'EvalueServe', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '1', '2', '3', '4', '5'],
      datasets: [
        {
          data: [10000000, 5000000, 3000000, 2800000, 2400000, 2000000, 1800000, 1400000, 1150000, 999999, 500000, 100000, 10000, 0, 0, 0, 0],
          backgroundColor: chartColors.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 1000000,
              min: 0,
              max: 6000000,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },
  latestBarChartTeamLeaderWise: {
    data: {
      labels: ['Steffi', 'Gausia', 'Neha', 'Jhimi', 'Sheeba', 'Dhiraj', 'Nisha', 'Almas'],
      datasets: [
        {
          data: [10000000, 5000000, 3000000, 2800000, 2400000, 2000000, 1800000, 1400000],
          backgroundColor: chartColors.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 2000000,
              min: 0,
              max: 10000000,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },
  latestBarChartRecruiterWise: {
    data: {
      labels: ['Steffi Menezes', 'Gausia Adoni', 'Neha', 'Jhimi', 'Sheeba', 'Dhiraj', 'Nisha', 'Almas', 'Siddharth', 'Seema', 'Tanzeem', 'Rahul', 'Mitali', 'Sheetal', 'Nazneen'],
      datasets: [
        {
          data: [10000000, 5000000, 3000000, 2800000, 2400000, 2000000, 1800000, 1400000, 1200000, 1000000, 1000000, 800000, 600000, 550000, 550000],
          backgroundColor: chartColors.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 1500000,
              min: 0,
              max: 6000000,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },
  lineAreaChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'top',
        align: 'start',
        display: false,
        labels: {
          usePointStyle: false,
          padding: 15,
          boxWidth: 8,
        },
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: true,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: 'transparent',
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 1000000,
              min: 0,
              max: 8000000,
              fontColor: chartColors.labelColor,
            },
            scaleLabel: {
              display: false,
            },
          },
        ],
      },
    },
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          data: [2750000, 900000, 3000000, 450000, 6500000, 850000, 150000, 1200000, 5000000, 6000000, 8000000, 2800000],
          lineTension: 0,
          backgroundColor: chartColors.blueLightColor,
          pointStyle: 'circle',
          borderColor: 'transparent',
          pointRadius: 0.5,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: chartColors.blueLightColor,
          pointHoverBorderColor: $themeColors.light,
        },
      ],
    },
  },
  }
