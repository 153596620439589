<template>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Team Lead Wise</b-card-title>
        
      </b-card-header>
  
      <!-- chart -->
      <b-card-body>
        <chartjs-component-bar-chart
          :height="400"
          :data="JSON.parse(JSON.stringify(chartData))"
          :options="JSON.parse(JSON.stringify(chartOptions))"
        />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BCardTitle, 
  } from 'bootstrap-vue'
  
  import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
  import chartjsData from './chartjsData'
  
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BCardTitle,
      ChartjsComponentBarChart,
    },
    data() {
      return {
        chartjsData,
      }
    },
    props: {
    
    chartData: {
      type: Object,
      required: false,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
  
  },
  }
  </script>
  
  <style lang="scss">

  </style>
  