<template>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Clientwise Revenue</b-card-title>
        
      </b-card-header>
  
      <!-- chart -->
      <b-card-body>
        <chartjs-component-bar-chart
        :height="400"
        :data="JSON.parse(JSON.stringify(chartData))"
        :options="JSON.parse(JSON.stringify(chartOptions))"
      />
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardBody, BCardTitle, BIconNutFill, 
  } from 'bootstrap-vue'

  import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
  //import chartjsData from './chartjsData'
  
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardBody,
      BCardTitle,
      ChartjsComponentBarChart,
    },
    data() {
      return {
        //chartjsData,
        //rangePicker: ['2019-05-01', '2019-05-10'],
        //chartData: null
      }
    },
    props: {
    
      chartData: {
        type: Object,
        required: false,
        immediate: true
      },
      chartOptions: {
        type: Object,
        required: false,
        immediate: true
      },
    
    },
    // watch: {
    //   chartData(newVal, oldVal) {
    //     this.chartData = JSON.parse(JSON.stringify(newVal))
    //     console.log('parentData changed from', oldVal, 'to', newVal);
    //     // Here you can add the logic that should be executed when parentData changes.
    //   }
    // }
  }
  </script>
  
  <style lang="scss">
  </style>
  